import { ActionType } from "@/store/actions/type";
import { Commit } from "vuex";
import { IResult } from "@/interfaces/IResult";
import { MutationType } from "@/store/mutations/type";
import Game from "@/services/game";
import { AxiosResponse } from "axios";
import { IGame } from "@/interfaces/IGame";
import { IPlayer } from "@/interfaces/IPlayer";
import { ICurrency } from "@/interfaces/ICurrency";
import { IChip } from "@/interfaces/IChip";
import { Chip } from "@/model/chip";
import { IColorChip } from "@/interfaces/IColorChip";
import { ISimpleBet } from "@/interfaces/ISimpleBet";
import { IStoreType } from "@/interfaces/IStoreType";
import { IBet } from "@/interfaces/IBet";
import { IOperator } from "@/interfaces/IOperator";
import { Result } from "@/model/Result";
import { IParamHistoryPlayer } from "@/interfaces/IParamHistoryPlayer";
import { IHistoryPlayer } from "@/interfaces/IHistoryPlayer";
import {IParamCloseGame} from "@/interfaces/IParamCloseGame";
import {TypePlayer} from "@/interfaces/TypePlayer";
import { SocketIo } from "@/model/SocketIo";

export default {
  [ActionType.UPDATE_RESULT]: async (
    { commit }: { commit: Commit },
    params: { gameUuid: string }
  ): Promise<void> => {
      try{
        const result: AxiosResponse = await Game.getResults(params.gameUuid);
        const array_aux: IResult[] = [];
        result.data.forEach((d: IResult): void => {
          if (d.result !== 0)
            array_aux.push(new Result(d.result, d.jackpot, d.multiplier));
        });
        commit(MutationType.SET_LAS_RESULT, array_aux);
      }catch (e) {
        console.error(e);
      }


   
  },
  [ActionType.CHECK_GAME]: async (
    {
      commit,
    }: {
      commit: Commit;
    },
    params: { operatorUuid: string; casinoId: string; token: string }
  ): Promise<boolean> => {
   
    try{
      const result: AxiosResponse = await Game.launch(
        params.operatorUuid,
        params.casinoId,
        params.token
      );
      //console.log("Launch", result);
      const game: IGame = {
        liveVideo: result.data.casino.urlTransmision,
        id: result.data.casino._id,
        uuid: result.data.casino.uuid,
        max:result.data.limits.maxBet,
        min:result.data.limits.minBet,
        providerId: result.data.casino.providerId,
        figures: result.data.limits.figures,
        logo: result.data.casino.logo,


      };
      const player: IPlayer = {
        name: result.data.player.username,
        lastBalance: result.data.player.lastBalance,
        userId: result.data.player.userId,
        uuid: result.data.player.uuid,
        type: result.data.player.type
      };
      const currency: ICurrency = {
        name: result.data.currency.name,
        uuid: result.data.currency.uuid,
        isoCode: result.data.currency.isoCode,
        short: result.data.currency.isoCode,
      };
      const operator: IOperator = {
        name: result.data.operator.name,
        uuid: result.data.operator.uuid,
      }
      const chips: IChip[] = [];
      result.data.chips.forEach(
        (c: {
          _id: string;
          value: number;
          color: IColorChip;
          currency: string;
        }): boolean => {
          const id: string = c._id;
          const value: number = c.value;
          const currency: string = c.currency;
          const color: IColorChip = c.color;
          const chip: IChip = new Chip(id, value, color, currency, false);
          chips.push(chip);
          return true;
        }
      );
      chips.sort((a: IChip, b: IChip): number => {
        return a.value - b.value;
      });
      const bet: IBet = {
        player: result.data.player.uuid,
        gameId: result.data.casino.uuid,
        currency: result.data.currency.isoCode,
        platform: "Desktop",
        user_id: result.data.player.userId,
        operatorId: result.data.operator.uuid,
        totalAmount: 0,
        round: "",
        bet: [],
      };
      chips[0].active = true;
      const lobby_url = new URL(result.data.casino.lobby);
      commit(MutationType.SET_BUTTON_SUPPORT,result.data.buttons.support);
      commit(MutationType.SET_BUTTON_LOBBY,result.data.buttons.lobby);
      commit(MutationType.SET_MAX_BET_FIGURE,result.data.casino.maxBetFigures);
      commit(MutationType.SET_BACKGROUND_IMAGE,result.data.casino.imgBackground);
      commit(MutationType.SET_CHIPS, chips);
      commit(MutationType.SET_CURRENCY, currency);
      commit(MutationType.SET_GAME, game);
      commit(MutationType.SET_PLAYER, player);
      commit(MutationType.SET_BET, bet);
      commit(MutationType.SET_OPERATOR,operator);
      commit(MutationType.SET_LOBBY,result.data.casino.lobby);
      commit(MutationType.SET_LOBBY_HOST,lobby_url.protocol+"//"+lobby_url.host)
      
      if(result.data.player.type === TypePlayer.VIDEO){
        commit(MutationType.SET_VIDEO_ONLY,true);
      }
      if(result.data.player.type === TypePlayer.BOARD){        
        commit(MutationType.SET_BOARD_ONLY,true);
      }
      if(result.data.player.type === TypePlayer.AGENT)
      {
        commit(MutationType.SET_AGENT_ONLY,true);
      }
      return true;
    }catch (e) {
      console.error(e);
      return false;
    }
  },
  [ActionType.ADD_BET]: async (
    { commit, state }: { commit: Commit;  state: IStoreType },
    bet: ISimpleBet
  ): Promise<void> => {
    const currentBet: IBet | null = state.bet;
    const player: IPlayer | null = state.player;
    if (currentBet && player) {
      const bet_aux = currentBet.bet.find((v:ISimpleBet)=>v.number === bet.number)
      if(!bet_aux)
        currentBet.bet.push(bet);
      else{
        bet_aux.amount += bet.amount;
        bet_aux.amount =  parseFloat(bet_aux.amount.toPrecision(2));
      } 
      commit(MutationType.SET_BET, currentBet);
      player.lastBalance -= bet.amount;
    }
  },
  [ActionType.SEACH_HISTORY_PLAYER]: async ( { commit }: { commit: Commit },
    params:IParamHistoryPlayer): Promise<IHistoryPlayer | null> => {
       try {
        console.log(commit);
        const result: AxiosResponse = await Game.getHistoryPlayer(params);
        return result.data;          
       } catch (error) {
        console.log("Error",error);
        return null;
       }
    },
    [ActionType.GO_TO_LOBBY]: async ( { commit }: { commit: Commit },
    params:IParamCloseGame): Promise<boolean> => {
       try {
          console.log(commit);
          await Game.notifyCloseGame(params)
         return true;
       } catch (error) {
        console.log("Error",error);
        return false;
       }
    },
  [ActionType.ADD_AUTOMATIC_BET]: async ({ commit, state }: { commit: Commit; state: IStoreType }):Promise<void | null>=>{
    if(state.bet===null)return;
    if(!state.player?.lastBalance || state.player.lastBalance === 0 )return;

      const totalAmount:number = state.lastBet.reduce(
          (total: number, element: ISimpleBet): number => {
            return total + element.amount;
          },0 );
      if (state.player?.lastBalance < totalAmount) return;
    commit(MutationType.ADD_AUTOMATIC_BET);
    commit(MutationType.REST_BALANCE,totalAmount);
  },
  [ActionType.EMIT_TICKET]: async ({ state }: { commit: Commit; state: IStoreType }):Promise<void | null> =>{
    if(!state) return;
    
    if(!state.game
      || !state.game.uuid 
      || !state.game.providerId
    )return;
    
    if(!state.operator
      || !state.operator.uuid
      )return;
    if(!state.player
      || !state.player.userId

    ){
      return;
    }
    if(!state.bet)return;
    
    SocketIo.get_Instance({
      gameUuid: state.game.uuid,
      userId: state.player.userId,
      operator: state?.operator?.uuid,
      providerId: state?.game?.providerId
    }).emit("ticket",state.bet);
  }

};
