import { IResolution } from "./IResolution";
export interface IResolutionType {
    auto: IResolution;
    hd: IResolution;
    md: IResolution;
    ld: IResolution;
}
export const ResolutionType: IResolutionType = {
    auto: { width: "auto", height: "auto" },
    hd: { width: "1920px", height: "1080px" },
    md: { width: "1280px", height: "720" },
    ld: { width: "960px", height: "540px" },
};